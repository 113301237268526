.skills__container {
  flex: 1;
  max-width: 1100px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.skills__logo{
  max-width: 150px;
}

.skills__header {
  width: 100%;
  height: 80px;
  flex-direction: row;
  justify-content: space-between;
}
.skills__user{
  padding: 15px 0;
  flex-direction: column;
  justify-content: center;
}
.skills__user-name{
  font-size: 20px;
  margin: 0;
}
.skills__user-dateUpdate{
  font-size: 9px;
  margin: 0;
}
.skills__content{
  flex: 1;
  justify-content: center;
  align-items: center;
}
.skills__quadrants{
  width: 1200px;
  height: 600px;
  background-color: #338e32;
  flex-wrap: wrap;
  position: relative;
}
.skills__quadrants__content{
  overflow: auto;
  height: 100%;
  width: 100%;
}
.skills__quadrants__quadrant{
  cursor: pointer;
  width: 50%;
  height: 50%;
  position: absolute;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px 5px 5px 10px;
  box-sizing: border-box;
  transition: all .3s;
}
.skills__quadrants__quadrant__label {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);
}
.skills__quadrants__arrow{
  width: 100%;
  height: 4px;
  position: absolute;
  background: #000;
  top: 50%;
  left: 0;
  margin-top: -2px;
  z-index: 3;
}
.skills__quadrants__arrow.--top{
  width: 50%;
  left: 25%;
  transform-origin: 50% 50%;
  transform: rotate(-90deg);
}
.skills__quadrants__arrow::before{
  content: '';
  display: block;
  position: absolute;
  border: 8px solid #000;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  top: -6px;
  left: 100%;
  width: 0;
  height: 0;
}
.skills__quadrants__chip{
  margin-bottom: 5px;
  margin-right: 5px;
}
.skills__quadrants__quadrant.--p1{
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
}
.skills__quadrants__quadrant.--p1:hover{
  background-color: rgba(255, 255, 255, 0.8);
}
.skills__quadrants__quadrant.--p2{
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.skills__quadrants__quadrant.--p2:hover{
  background-color: rgba(255, 255, 255, 0.6);
}
.skills__quadrants__quadrant.--p3{
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.3);
}
.skills__quadrants__quadrant.--p3:hover{
  background-color: rgba(255, 255, 255, 0.4);
}
.skills__quadrants__quadrant.--p4{
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
}
.skills__quadrants__quadrant.--p4:hover{
  background-color: rgba(255, 255, 255, 0.3);
}
.skills__quadrants__quadrant::before,.skills__quadrants__quadrant::after{
  display: block;
  position: absolute;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.skills__quadrants__quadrant.--p1::before{
  content: 'Pouco Conhecimento';
  left: 0;
  top: 100%;
  width: 50%;
  transform-origin: top left;
  transform: rotate(-90deg) translateY(-100%);
}
.skills__quadrants__quadrant.--p1::after{
  content: 'Experiência Pessoal';
  top: 100%;
  left: 0;
  width: 100%;
}
.skills__quadrants__quadrant.--p2::before{
  content: 'Pouco Conhecimento';
  left: 0;
  top: 100%;
  width: 50%;
  transform-origin: top left;
  transform: rotate(-90deg) translateY(-100%);
}
.skills__quadrants__quadrant.--p2::after{
  content: 'Experiência Profissional';
  bottom: 100%;
  left: 0;
  width: 100%;
}
.skills__quadrants__quadrant.--p3::before{
  content: 'Bom Conhecimento';
  left: 100%;
  top: 0;
  width: 50%;
  transform-origin: top left;
  transform: rotate(90deg) translateY(-100%);
}
.skills__quadrants__quadrant.--p3::after{
  content: 'Experiência Pessoal';
  top: 100%;
  left: 0;
  width: 100%;
}
.skills__quadrants__quadrant.--p4::before{
  content: 'Bom Conhecimento';
  left: 100%;
  top: 0;
  width: 50%;
  transform-origin: top left;
  transform: rotate(90deg) translateY(-100%);
}
.skills__quadrants__quadrant.--p4::after{
  content: 'Experiência Profissional';
  bottom: 100%;
  left: 0;
  width: 100%;
}

.skills__modal{
  padding: 30px 0 50px;
}
.skills__modal__chip{
  margin-bottom: 5px!important;
  margin-right: 5px!important;
}
.skills__modal__add{
  align-items: flex-end;
}
.skills__modal__add button{
  margin-left: 20px;
  margin-bottom: 4px;
}
::after,::before{
  box-sizing: border-box;
}