.login__container{
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.login__logo{
  max-width: 150px;
  margin-bottom: 15px;
}
.login__form{
  display: flex;
  flex-direction: column;
}
.login__form-input {
  margin-bottom: 15px;
}
.login__form-btn{
  padding-top: 20px;
  justify-content: flex-end;
}

.backdrop{
  z-index: 100000!important;
  color: #fff;
}